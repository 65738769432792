import { Karrot, Hooks, ISettings } from '@karrot/core';

export class CookiesPolicy {

    constructor(public element: HTMLElement, public hooks: Hooks, public settings: ISettings) {
    }

    public kOnInit(): void {
        // tslint:disable: max-line-length
        const cookiesText = `Strona korzysta z plików cookies.`;
        if (this.get('cookies-policy') === 'true') {
            return;
        }

        this.element.innerHTML = `
        <div class="c-cookies">
            <div>
                <p class="c-cookies__text">${cookiesText}</p>
            </div>
            <div>
                <a href="#" class="c-cookies__button">Zamknij<i class="fas fa-times"></i></a>
            </div>
        </div>
        `;

        const cookies = this.element.querySelector('.c-cookies');
        const cookiesButtonElement = this.element.querySelector('.c-cookies__button');

        setTimeout(() => {
            cookies.classList.add('is-active');
        }, 2000);

        if (cookiesButtonElement) {
            cookiesButtonElement.addEventListener('click', (e) => {
                e.preventDefault();

                this.set('cookies-policy', 'true', 30);
                cookies.classList.remove('is-active');

                setTimeout(() => {
                    this.element.innerHTML = '';
                }, 2000);
            });
        }
    }

    public set(cookieName: string, cookieValue: string, expireDays: number = 30): void {
        const d = new Date();
        d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
        const expires = "expires=" + d.toUTCString();
        document.cookie = cookieName + "=" + cookieValue + ";" + expires + ";path=/";
    }

    public get(cookieName: string): string {
        const name = cookieName + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');

        for (let c of ca) {
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }

            if (c.indexOf(name) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return "";
    }
}
