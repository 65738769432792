import { ISettings, Hooks } from "@karrot/core";

import '../assets/marker-icon.png';

// tslint:disable-next-line: interface-over-type-literal
export type TMapSettings = {
    lat: number;
    lng: number;
    styles: google.maps.MapTypeStyle[];
};

const markerIcon: string = './assets/marker-icon.png';

export class MapController {
    private map: google.maps.Map;
    private mapOptions: google.maps.MapOptions;

    constructor(private element: HTMLElement,
                public hooks: Hooks,
                private settings: TMapSettings) {
    }

    public kOnInit(): void {
        const center = {lat: this.settings.lat, lng: this.settings.lng};

        if (window.innerWidth >= 768) {
            center.lng += .007;
        }

        this.mapOptions = {
            center,
            scrollwheel: false,
            // tslint:disable-next-line
            styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}],
            zoom: 15,
        };

        console.log(document.body.clientWidth);

        this.map = new google.maps.Map(this.element, this.mapOptions);

        const marker = new google.maps.Marker({
            icon: markerIcon,
            map: this.map,
            position: {lat: this.settings.lat, lng: this.settings.lng},
            title: 'Watchconcept',
        });
    }

}
