import { Hooks, ISettings, Karrot } from "@karrot/core";
import { DOM } from "@karrot/common";

export class AnimationsManager {
    public animationChildren: Karrot[] = Karrot.getMany('animation-child', this.element);
    public main: Karrot = Karrot.get('main');
    public isActivated: boolean = false;
    private offset: { top: number, left: number };

    constructor(public element: HTMLElement, public hooks: Hooks/*, settings: ISettings*/) {
        // this.settings = Object.assign({}, this.settings, settings);
    }

    public kOnInit(): void {

        for (const child of this.animationChildren) {
            if (child.settings.isImage) {
                child.element.classList.add('k-image-anim');
            } else {
                child.element.classList.add('k-anim');
            }
        }

        this.main.addAction('pageLoaded', () => {
            this.setOffset();
            this.onScroll();

            window.addEventListener('scroll', () => {
                this.onScroll();
            });
        });
    }

    public async onScroll(): Promise<void> {
        if (this.isActivated) {
            return;
        }

        const scrollY = window.scrollY + (window.innerHeight / 1.2);
        const offset = this.offset.top + 30;

        if (offset > scrollY) {
            return;
        }

        for (const child of this.animationChildren) {
            const delay = parseInt(child.settings.delay, 0) || 400;
            await new Promise((r) => {
                setTimeout(() => {
                    r();
                }, delay);
            });

            child.element.classList.add('is-active');
        }

        this.isActivated = true;
    }

    private setOffset(): { top: number, left: number } {
        let parent = this.element;

        const offset = {
            left: 0,
            top: 0,
        };

        while (parent.offsetParent) {
            offset.top += parent.offsetTop;
            offset.left += parent.offsetLeft;
            parent = parent.offsetParent as HTMLElement;
        }

        this.offset = offset;

        return this.offset;
    }
}
