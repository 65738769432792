import { Karrot, Hooks } from '@karrot/core';
import { DOM } from '@karrot/common';

export class App {

    private navLinks: HTMLElement[] = Karrot.getMany('nav-link', HTMLElement).reverse();

    constructor(public element: HTMLElement, public hooks: Hooks) {
    }

    public kOnInit(): void {

        window.addEventListener('scroll', () => {
            for (const navLink of this.navLinks) {
                const target = document.querySelector(navLink.getAttribute('href')) as HTMLElement;

                if (!target) {
                    continue;
                }

                const offsetTop = target.offsetTop;
                const scrollY = window.scrollY + (window.innerHeight / 2);

                if (offsetTop < scrollY) {
                    DOM(this.navLinks)
                        .state.set('active', false);

                    DOM(navLink).state.set('active', true);

                    return;
                } else {
                    DOM(navLink).state.set('active', false);
                }

            }
        });
    }
}
