import { Karrot, Hooks } from "@karrot/core";
import { FormAjax, FormValidation, ScrollTo, DOM } from '@karrot/common';

import { App } from "./app";
import { AnimationsManager } from "./animations";
import { MapController } from "./map";
import { CookiesPolicy } from "./cookies-policy";

Karrot.init();
Karrot.attach('my-form', FormAjax, FormValidation, (element: HTMLElement, hooks: Hooks) => {
    hooks.addAction('formAjax.sent', (response: Response) => {
        if (!response) {
            return;
        }

        const textareas = Array.from(element.querySelectorAll('textarea'));

        for (const textarea of textareas) {
            textarea.value = '';
        }
    });
});
Karrot.attach('link', ScrollTo);
Karrot.attach('app', App);

Karrot.attach('animation', AnimationsManager);
Karrot.attach('map', MapController);
Karrot.attach('cookies-policy', CookiesPolicy);

Karrot.attach('main', (e: HTMLElement, hooks: Hooks) => {
    document.addEventListener('readystatechange', () => {
        if (document.readyState === 'complete') {
            setTimeout(() => {
                e.classList.add('is-loaded');
            }, 1000);

            setTimeout(() => {
                hooks.doAction('pageLoaded');
            }, 1100);
        }
    });
});

Karrot.attach('nav', (e: HTMLElement) => {
    window.addEventListener('scroll', () => {
        if (window.scrollY > 0) {
            e.classList.add('is-scroll');
        } else {
            e.classList.remove('is-scroll');
        }
    });
});

Karrot.attach('menu-toggler', (element: HTMLElement) => {
    const nav = Karrot.get('nav', HTMLElement);

    DOM(element).event.add('click', () => {
        DOM(element, nav).state.toggle('active');
    });
});

Karrot.attach('site-scrollbar-step', (element: HTMLElement) => {
    window.addEventListener('scroll', () => {
        const scrollY = window.scrollY;
        const bodyHeight = document.body.scrollHeight - window.innerHeight;

        const height = Math.floor(scrollY / bodyHeight * 80);

        element.style.height = height + 'px';
    });
});
